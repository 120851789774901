import pages from "../pages/index";

const routes = [
  // Reseller
  {
    resource: "reseller",
    path: "reseller",
    element: pages.Reseller,
    children: [
      {
        path: "new",
        element: pages.CreateReseller,
      },
      {
        path: ":id",
        element: pages.EditReseller,
      },
    ],
  },
  // Company
  {
    resource: "company",
    path: "company",
    element: pages.Company,
    children: [
      { path: "new", element: pages.CreateCompany },
      { path: ":id", element: pages.EditCompany },
    ],
  },

  //   User Management
  {
    resource: "user",
    path: "user",
    element: pages.Account,
    children: [
      {
        path: "change-password/:id",
        element: pages.ChangePassword,
      },
      {
        path: "new",
        element: pages.CreateAccount,
      },
      {
        path: ":id",
        element: pages.AccountDetails,
      },
      {
        path: "change-password-user",
        element: pages.ChangePass,
      },
    ],
  },
  // Devices
  {
    resource: "taggedDevice",
    path: "devices",
    element: pages.Device,
    children: [
      { path: "initialized", element: pages.Device },
      { path: "groups", element: pages.DeviceGroupings },
      { path: "groups/new", element: pages.CreateGroup },
      { path: "groups/:id", element: pages.ViewGroup },
      { path: "groups/manage-devices/:id", element: pages.ManageDevices },
      {
        path: "initialized/device-details/:id",
        element: pages.DeviceInitDetails,
      },
      {
        path: "uninitialized/device-details/:id",
        element: pages.DeviceUninitDetails,
      },
      { path: "uninitialized", element: pages.UninitializedDevice },
      { path: "uninitialized/tag-device/:id", element: pages.TagDeviceUninit },
      {
        path: "initialized/transactions&logs/:id",
        element: pages.Transactions,
      },
      { path: "initialized/edit-device/:id", element: pages.EditDevice },
      {
        path: "uninitialized/initialize-device/:id",
        element: pages.InitializeDevice,
      },
      { path: "initialized/tag-device/:id", element: pages.TagDeviceInit },
    ],
  },
  // Untagged Devices
  {
    resource: "untaggedDevice",
    path: "untaggedDevices",
    element: pages.UntaggedDevice,
    children: [
      { path: "tag-device/:id", element: pages.TagDevice },
      { path: "register", element: pages.Register },
    ],
  },

  // Transactions
  {
    resource: "transaction",
    path: "transaction",
    element: pages.Transaction,
    children: [],
  },
  // Report
  {
    resource: "transaction",
    path: "report",
    element: pages.DailyReport,
    children: [
      { path: "daily", element: pages.DailyReport },
      { path: "monthly", element: pages.MonthlyReport },
    ],
  },
  // Logs
  {
    resource: "logs",
    path: "logs",
    element: pages.Logs,
    children: [],
  },
];

export default routes;
