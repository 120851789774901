import axios from "axios";
// eslint-disable-next-line no-unused-vars

// export const endpoint = "https://staging.api.nebula.payrallel.io/";
export const endpoint = "https://api.nebula.payrallel.io";

const instance = axios.create({ baseURL: endpoint });

const API = (method, url, data, options) => {
  return instance({ method, url, data, withCredentials: true, ...options });
};

export default API;
