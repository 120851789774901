const routes = [
  {
    resource: "reseller",
    icon: "ShoppingCart",
    name: "Resellers",
    path: "reseller",
  },
  {
    resource: "company",
    icon: "BuildingStorefront",
    name: "Companies",
    path: "company",
  },
  {
    resource: "user",
    icon: "UserGroup",
    name: "Users",
    path: "user",
  },

  {
    resource: "untaggedDevice",
    icon: "SignalSlash",
    name: "Untagged Devices",
    path: "untaggedDevices",
  },
  {
    resource: "taggedDevice",
    icon: "ServerStack",
    name: "Devices",
    path: "devices",
    routes: [
      {
        icon: "ExclamationCircle",
        path: "devices/initialized",
        name: "Initialized Devices",
      },
      {
        icon: "Link",
        path: "devices/groups",
        name: "Device Groupings",
      },
    ],
  },
  {
    resource: "transaction",
    icon: "QueueList",
    name: "Transactions",
    path: "transaction",
  },
  {
    resource: "transaction",
    icon: "ArrowDownTray",
    name: "Report",
    path: "report",
    routes: [
      {
        icon: "Inbox",
        path: "report/daily",
        name: "Daily",
      },
      {
        icon: "InboxStack",
        path: "report/monthly",
        name: "Monthly",
      },
    ],
  },
  // {
  //   resource: "logs",
  //   icon: "DocumentChartBar",
  //   name: "Server Logs",
  //   path: "logs",
  // },
];

export default routes;
