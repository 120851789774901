import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const ProtectedRoute = ({ resource, authenticatedRedirect, children }) => {
  const { authenticated, user } = useContext(AuthContext);

  if (authenticated === undefined) return null;

  if (authenticated) {
    const permissions = user?.Account?.permissions;
    if (permissions.includes(resource)) return children;
    else return <Navigate to={`/app/${authenticatedRedirect}`} replace />;
  }

  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
