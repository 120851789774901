import React, { useContext } from "react";
import Icon from "../Icon";
import sidebarRoutes from "../../routes/sidebar";
import { NavLink } from "react-router-dom";
import SidebarSubmenu from "./SidebarSubmenu";
import { AuthContext } from "../../context/AuthContext";
import API from "../../api/api";
import { useQuery } from "@tanstack/react-query";

const SidebarContent = () => {
  const { user } = useContext(AuthContext);

  // Count Untagged Device
  const getUntaggedDeviceCount = async () => {
    const { data } = await API("GET", `device/untagged/count`);
    return data.count;
  };

  const { data: untaggedCount } = useQuery(
    ["untaggedCount"],
    getUntaggedDeviceCount
  );
  // return (
  //   <div className="py-4 text-nicegreen">
  //     <a
  //       className="ml-6 text-xl font-bold uppercase text-nicegreen"
  //       href="/app/warehouse"
  //     >
  //       Nebula IOT
  //     </a>
  //     <p className="ml-6 text-lg font-bold text-nicegreen">Management Portal</p>
  //     <ul className="mt-6">
  //       {sidebarRoutes.map((route) =>
  //         route.routes ? (
  //           <SidebarSubmenu route={route} key={route.name} />
  //         ) : (
  //           <li className="relative px-6 py-3" key={route.name}>
  //             <NavLink
  //               to={`/app/${route.path}`}
  //               className={({ isActive }) =>
  //                 `${
  //                   isActive ? `text-nicegreen font-semibold` : `text-gray-600`
  //                 } inline-flex items-center w-full text-sm transition-colors duration-150 hover:text-nicegreen`
  //               }
  //             >
  //               <Icon
  //                 className="w-5 h-5"
  //                 aria-hidden="true"
  //                 icon={route.icon}
  //               />
  //               <span className="ml-4">
  //                 {route.name}{" "}
  //                 {route.name === "Untagged Devices" && (
  //                   <span
  //                     className="ml-1"
  //                     style={{
  //                       backgroundColor: "#fa3e3e",
  //                       color: "#fff",
  //                       borderRadius: "20%",
  //                       padding: `1px 5px`,
  //                       fontSize: "12px",
  //                     }}
  //                   >
  //                     {untaggedCount}
  //                   </span>
  //                 )}
  //               </span>
  //             </NavLink>
  //           </li>
  //         )
  //       )}
  //     </ul>
  //   </div>
  // );

  const masterRoutes = [
    ...sidebarRoutes,
    {
      resource: "logs",
      icon: "DocumentChartBar",
      name: "Server Logs",
      path: "logs",
    },
  ];
  return (
    <div className="py-4 text-nicegreen">
      <a
        className="ml-6 text-xl font-bold uppercase text-nicegreen"
        href="/app/warehouse"
      >
        Nebula IOT
      </a>
      <p className="ml-6 text-lg font-bold text-nicegreen">Management Portal</p>
      <ul className="mt-6">
        {(user?.Account?.type === "master" ? masterRoutes : sidebarRoutes)

          .filter((i) => {
            if (user) {
              return user.Account.permissions.includes(i.resource);
            }
            return true;
          })
          .map((route) =>
            route.routes ? (
              <SidebarSubmenu route={route} key={route.name} />
            ) : (
              <li className="relative px-6 py-3" key={route.name}>
                <NavLink
                  to={`/app/${route.path}`}
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? `text-nicegreen font-semibold`
                        : `text-gray-600`
                    } inline-flex items-center w-full text-sm transition-colors duration-150 hover:text-nicegreen`
                  }
                >
                  <Icon
                    className="w-5 h-5"
                    aria-hidden="true"
                    icon={route.icon}
                  />
                  <span className="ml-4">
                    {route.name}{" "}
                    {/* {route.name === "Devices" && untaggedCount > 0 && (
                      <span
                        className="ml-1"
                        style={{
                          backgroundColor: "#fa3e3e",
                          color: "#fff",
                          borderRadius: "20%",
                          padding: `1px 5px`,
                          fontSize: "12px",
                        }}
                      >
                        New
                      </span>
                    )} */}
                  </span>
                </NavLink>
              </li>
            )
          )}
      </ul>
    </div>
  );
};

export default SidebarContent;
