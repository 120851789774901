import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import { Transition } from "@windmill/react-ui";
import { useLocation } from "react-router-dom";
import API from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../context/AuthContext";

function SidebarSubmenu({ route }) {
  const { user } = useContext(AuthContext);

  const loc = useLocation().pathname;
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(true);

  function handleDropdownMenuClick() {
    setIsDropdownMenuOpen(!isDropdownMenuOpen);
  }

  // Count Uninitialized Device
  const getUninitializedDeviceCount = async () => {
    const { data } = await API("GET", `device/uninitialized/count`);
    return data.count;
  };

  const { data: uninitializedCount } = useQuery(
    ["uninitializedCount"],
    getUninitializedDeviceCount
  );
  return (
    <li className="relative px-6 py-3" key={route.name}>
      <button
        className="focus:outline-none inline-flex items-center justify-between w-full text-sm transition-colors duration-150 hover:text-nicegreen"
        onClick={handleDropdownMenuClick}
        aria-haspopup="true"
      >
        <span className="inline-flex items-center">
          {isDropdownMenuOpen ? (
            <Icon
              className="w-5 h-5 text-nicegreen"
              aria-hidden="true"
              icon={route.icon}
              type="solid"
            />
          ) : (
            <Icon
              className="w-5 h-5 text-gray-600"
              aria-hidden="true"
              icon={route.icon}
              type="solid"
            />
          )}
          <span className="ml-4">
            {route.name}{" "}
            {route.name === "Devices" && (
              <span
                className="ml-1"
                style={{
                  backgroundColor: "#fa3e3e",
                  color: "#fff",
                  borderRadius: "20%",
                  padding: `1px 5px`,
                  fontSize: "12px",
                }}
              >
                {uninitializedCount ?? "New"}
              </span>
            )}
          </span>
        </span>
        {isDropdownMenuOpen ? (
          <Icon
            className="w-5 h-5 text-nicegreen"
            aria-hidden="true"
            icon="ChevronDown"
            type="solid"
          />
        ) : (
          <Icon
            className="w-5 h-5 text-nicegreen"
            aria-hidden="true"
            icon="ChevronRight"
            type="solid"
          />
        )}
      </button>
      <Transition
        show={isDropdownMenuOpen}
        enter="transition-all ease-in-out duration-300"
        enterFrom="opacity-25 max-h-0"
        enterTo="opacity-100 max-h-xl"
        leave="transition-all ease-in-out duration-300"
        leaveFrom="opacity-100 max-h-xl"
        leaveTo="opacity-0 max-h-0"
      >
        <ul
          className="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-600"
          aria-label="submenu"
        >
          {route.routes.map((r, i) => (
            <Link
              className="w-full"
              to={r.path}
              key={i}
              style={{
                display:
                  user?.Account?.type === "reseller" &&
                  r.path === "devices/groups"
                    ? "none"
                    : "contents",
              }}
            >
              <li
                className={`${
                  loc.includes(r.path) ? `text-nicegreen` : `text-gray-600`
                } flex px-2 py-2 transition-colors duration-150 hover:text-nicegreen`}
                key={r.name}
              >
                {r.icon && (
                  <Icon
                    className="w-5 h-5"
                    aria-hidden="true"
                    icon={r.icon}
                    type="outline"
                  />
                )}
                <span className="ml-4">{r.name}</span>
              </li>
            </Link>
          ))}
        </ul>
      </Transition>
    </li>
  );
}

export default SidebarSubmenu;
