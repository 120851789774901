import React, { useMemo, useReducer } from "react";
import API from "../api/api";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const initialState = { user: undefined, authenticated: undefined };

  const [state, dispatch] = useReducer((prevState, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case "SIGN_IN":
        return {
          user: action.user,
          authenticated: true,
        };
      case "SIGN_OUT":
        return {
          user: null,
          authenticated: false,
        };
    }
  }, initialState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => ({
    signIn: async (payload) => {
      const { data } = await API("POST", "/auth/login", payload);
      if (data) dispatch({ type: "SIGN_IN", user: data.user });
    },
    restoreSession: (payload) => {
      if (payload?.status) {
        dispatch({ type: "SIGN_IN", user: payload });
      } else dispatch({ type: "SIGN_OUT" });
    },
    signOut: async () => {
      await API("POST", "/auth/logout");
      dispatch({ type: "SIGN_OUT" });
    },
  }));

  return (
    <AuthContext.Provider value={{ ...state, ...value }}>
      {children}
    </AuthContext.Provider>
  );
};
