import { lazy } from "react";

const pages = {
  Page404: lazy(() => import("./404")),
  // Login
  Login: lazy(() => import("./Login")),
  ForgotPassword: lazy(() => import("./ForgotPassword")),
  ResetPassword: lazy(() => import("./ResetPassword")),
  // Reseller
  Reseller: lazy(() => import("./Reseller")),
  CreateReseller: lazy(() => import("./Reseller/CreateReseller")),
  EditReseller: lazy(() => import("./Reseller/EditReseller")),
  // Company
  Company: lazy(() => import("./Company")),
  CreateCompany: lazy(() => import("./Company/CreateCompany")),
  EditCompany: lazy(() => import("./Company/EditCompany")),
  // User Account
  Account: lazy(() => import("./User/Account/index.js")),
  CreateAccount: lazy(() => import("./User/Account/CreateAccount")),
  EditAccount: lazy(() => import("./User/Account/EditAccount")),
  ChangePassword: lazy(() => import("./User/Account/ChangePassword")),
  ChangePass: lazy(() => import("./User/Account/ChangePass")),
  // Device
  Device: lazy(() => import("./Device/Tagged/Initialized")),
  DeviceInitDetails: lazy(() =>
    import("./Device/Tagged/Initialized/DeviceDetails")
  ),
  DeviceUninitDetails: lazy(() =>
    import("./Device/Tagged/Uninitialized/DeviceDetails")
  ),
  UntaggedDevice: lazy(() => import("./Device/Untagged")),
  Register: lazy(() => import("./Device/Untagged/Register.js")),
  TagDevice: lazy(() => import("./Device/Untagged/TagDevice")),
  TagDeviceInit: lazy(() => import("./Device/Tagged/Initialized/TagDevice")),
  TagDeviceUninit: lazy(() =>
    import("./Device/Tagged/Uninitialized/TagDevice")
  ),
  Transactions: lazy(() => import("./Device/Tagged/Transactions")),
  EditDevice: lazy(() => import("./Device/Tagged/Initialized/EditDevice")),
  InitializeDevice: lazy(() =>
    import("./Device/Tagged/Uninitialized/DeviceInitialization")
  ),
  UninitializedDevice: lazy(() => import("./Device/Tagged/Uninitialized")),
  //Device Groupings
  DeviceGroupings: lazy(() => import("./Device/DeviceGroupings")),
  CreateGroup: lazy(() => import("./Device/DeviceGroupings/CreateGroup")),
  ViewGroup: lazy(() => import("./Device/DeviceGroupings/ViewGroup")),
  ManageDevices: lazy(() => import("./Device/DeviceGroupings/ManageDevices")),
  // Transaction
  Transaction: lazy(() => import("./Transaction")),
  // Report
  MonthlyReport: lazy(() => import("./Report/Monthly")),
  DailyReport: lazy(() => import("./Report/Daily")),
  // Logs
  Logs: lazy(() => import("./Logs")),
};

export default pages;
