import React from "react";
import * as OHIcons from "@heroicons/react/24/outline";
import * as SHIcons from "@heroicons/react/24/solid";

const Icon = ({ icon, type, ...props }) => {
  const { ...icons } = type === "outline" ? OHIcons : SHIcons;
  const Icon = icons[`${icon}Icon`];
  return <Icon {...props} />;
};

export default Icon;
