import pages from "../pages/index";

const routes = [
  {
    path: "/login",
    element: pages.Login,
  },
  {
    path: "/forgotPassword",
    element: pages.ForgotPassword,
  },
  {
    path: "/setPassword/:id",
    element: pages.ResetPassword,
  },
];

export default routes;
